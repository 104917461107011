/*animals hover fears*/

(function(){

    var animal = $('.animal__item_img');
    var item = $('.animal__item');

    animal.hover(

        function() {

            var currentItem = $(this).closest(item);
            var siblingsItems = currentItem.siblings();

            currentItem.addClass('active');
            siblingsItems.removeClass('active');

        },

        function() {

            var currentItem = $(this).closest(item);
            currentItem.removeClass('active');
        }

    );

})();


/*anchors*/

$(document).ready(function(){

    var anchor = $('.anchor');

    anchor.on("click", function (e) {
        e.preventDefault();

        var id  = $(this).attr('href'),
            top = $(id).offset().top;

        $('body,html').animate({scrollTop: top}, 1500);
    });
});

/*scroll top*/

$(document).ready(function(){
    $('#toTop').fadeOut();

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#toTop').fadeIn();
        } else {
            $('#toTop').fadeOut();
        }
    });
    $('#toTop').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 1000);
        return false;
    });
});
/*mob menu*/

(function(){

    var open = $('.header__btn_menu'),
        close = $('.header__menu_close'),
        menu = $('.header__menu_mob'),
        btnUp =$('.arrow-up');

    open.on('click', function() {
        menu.addClass('active');
        btnUp.hide();
    });

    close.on('click', function() {
        menu.removeClass('active');
        btnUp.hide();
    });

    $(document).bind( "mouseup touchend", function(e){

        var itemActive = $('.wrapper'),
            block = $(".header__menu_mob.active");

        if (!block.is(e.target)
            && block.has(e.target).length === 0) {

            block.removeClass('active');
        }
    });
})();

/*cut text in previews*/

(function(){

    var size = 190,
        mainSize = 270,
        artSize = 220,
        previewSize = 320,
        // newsContent= $('.articles__item_text'),
        mainContent= $('.article .article__desc, .vets-articles__item_text'),
        artContent= $('.main-article__similar .article__desc'),
        previewContent= $('.paginated .news__text');

    /*newsContent.each(function() {
        var text = $(this),
            newsText = text.text();

        if(newsText.length > size){
            text.text(newsText.slice(0, size) + ' ...');
        }
    });*/

    mainContent.each(function() {
        var text = $(this),
            newsText = text.text();

        if(newsText.length > mainSize){
            text.text(newsText.slice(0, mainSize) + ' ...');
        }
    });

    artContent.each(function() {
        var text = $(this),
            newsText = text.text();

        if(newsText.length > artSize){
            text.text(newsText.slice(0, artSize) + ' ...');
        }
    });

    previewContent.each(function() {
        var text = $(this),
            newsText = text.text();

        if(newsText.length > previewSize){
            text.text(newsText.slice(0, previewSize) + ' ...');
        }
    });

})();

/*faq questions*/

// (function(){
//
//     var question = $('.faq__questions_question');
//
//     question.on('click', function() {
//         var item = $(this).closest('.faq__questions_item');
//         item.toggleClass('active');
//     });
//
// })();

(function(){
    var btnUp =$('.arrow-up');
    var body = $('body');

    if(body.hasClass('body_popup')){
        btnUp.hide();
    } else {
        btnUp.show();
    }


})();


(function() {
    var isTheDoctor = sessionStorage.getItem('isTheDoctor');
    if (isTheDoctor != "yes") {
        $(".doctors-warning").fadeIn();
    }

    // $(".doctors-warning__close").on("click", function(e) {
    //     e.preventDefault();
    //     $(".doctors-warning").fadeOut();
    // });

    $(".doctors-warning__yes").on("click", function(e) {
        e.preventDefault();
        $(".doctors-warning").fadeOut();
        sessionStorage.setItem('isTheDoctor', 'yes');
    });
})();