/*opening form in faq*/

(function(){

    var btnAsk = $('.faq__expert_btnForm'),
        btnHide = $('.faq__expert_btnHide'),
        form = $('.faq__ask');

    btnAsk.on('click', function() {
        $(this).hide();
        form.addClass('active');
        btnHide.addClass('active');
    });

    btnHide.on('click', function() {
        $(this).removeClass('active');
        form.removeClass('active');
        btnAsk.show();
    });

})();

/*submit form*/

$(function() {
    var body = $('body');
    var btnUp = $('.arrow-up');

    $('.faq__ask_form').on('submit', function(e) {
        e.preventDefault();
        var $form = $(this),
            popupSubmit = $('.popups__submit .popup__item');

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize()
        }).done(function() {
            $form.find('[type="text"], textarea').val('');
            popupSubmit.addClass('popup__item_active');
            body.addClass('body_popup');
            btnUp.hide();
            dataLayer.push({'event': 'ga_vopros-otpravlen'});

        }).fail(function() {
            console.log('fail');
        });
    });
});