/*select shop*/

(function(){

    var btnWholesale = $('.buy__menu_item-wholesale'),
        btnRetail = $('.buy__menu_item-retail'),
        blockWholesale = $('.buy__shops_wholesale'),
        blockRetail = $('.buy__shops_retail');

    btnRetail.on('click', function() {
        $(this).addClass('active');
        blockWholesale.removeClass('active');
        btnWholesale.removeClass('active');
        blockRetail.addClass('active');
    });

    btnWholesale.on('click', function() {
        $(this).addClass('active');
        blockRetail.removeClass('active');
        btnRetail.removeClass('active');
        blockWholesale.addClass('active');
    });

})();