/*articles sliders*/

$(document).ready(function(){
    $('#owl-carousel_dog').owlCarousel({
        items:1,
        loop:true,
        nav:true,
        responsive:{
            999: {
                items: 1
            }
        }

    });
});

$(document).ready(function(){
    $('#owl-carousel_cat').owlCarousel({
        items:1,
        loop:true,
        nav:true

    });
});

$(document).ready(function(){
    $('#owl-carousel_similar').owlCarousel({
        items:1,
        loop:true,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            750:{
                items:2
            },
            1000:{
                items:3
            }
        }

    });
});

$(document).ready(function(){

    $('#owl-carousel_news').owlCarousel({
        items:1,
        loop:true,
        nav:true,
        responsive:{
            999:{
                items:1
            }
        }
    });

    var totalPages = $('.article__pages .total');
    var currentPages = $('.article__pages .current');
    // var countNews = $('.news').find('.news__item').length;
    // totalPages.text(countNews/2);

    var arrows = $('.news .owl-nav');
    var btnUp = $('.arrow-up');

    $('#owl-carousel_news').on('changed.owl.carousel', function(e) {
        totalPages.text(e.item.count);
        currentPages.text(++e.page.index);
    });

    function openPup(){

        var newsBtn = $('.news .news__detail, .news .news__title, .news .news__img  '),
            newsArticle = $('.popups__news .popup__item'),
            body = $('body');


        newsBtn.on('click', function (e) {

            var number = $('.article__pages .current');
            e.preventDefault();

            arrows.hide();
            btnUp.hide();

            newsArticle.eq(Number(number.html()) - 1).addClass('popup__item_active');
            body.addClass('body_popup');
        });

    }

    openPup();

    function closePup(){

        var close = $('.popup__item_close'),
            body = $('body');

        close.on('click', function (e) {
            e.preventDefault();

            var item = $(this).closest('.popup__item');
            arrows.show();
            btnUp.show();

            item.removeClass('popup__item_active');
            body.removeClass('body_popup');
        });


        $(document).mouseup(function (e){

            var itemActive = $('.popup__item_active'),
                block = $(".popup__item_active .popup__modal");

            if (!block.is(e.target)
                && block.has(e.target).length === 0) {

                itemActive.removeClass('popup__item_active');
                body.removeClass('body_popup');
                arrows.show();
                btnUp.show();
            }
        });

    }

    closePup();


});

// /*articles Popups close*/
//
// (function(){
//
//     var close = $('.popup__item_close'),
//         body = $('body');
//
//     close.on('click', function (e) {
//         e.preventDefault();
//
//         var item = $(this).closest('.popup__item');
//         arrows.show();
//
//         item.removeClass('popup__item_active');
//         body.removeClass('body_popup');
//     });
//
//
//     $(document).mouseup(function (e){
//
//         var itemActive = $('.popup__item_active'),
//             block = $(".popup__item_active .popup__modal");
//
//         if (!block.is(e.target)
//             && block.has(e.target).length === 0) {
//
//             itemActive.removeClass('popup__item_active');
//             body.removeClass('body_popup');
//         }
//     });
//
// })();
//
// /*resize for slider */
//
// $(document).ready(function() {
//
//     var wrapper = $('.article__wrapper');
//
//     if ($(window).width() >= '1000'){
//         $('.main-article__similar').addClass('large');
//         wrapper.css('left', '0%');
//     } else   {
//         $('.main-article__similar').removeClass('large')
//     }
//
//     $(window).resize(function() {
//         if ($(window).width() >= '1000'){
//             $('.main-article__similar').addClass('large');
//             wrapper.css('left', '0%');
//
//
//         } else   {
//             $('.main-article__similar').removeClass('large')
//         }
//
//     });
// });